<template>
  <div class="hello">
    <!-- <el-button type="info" plain>Info</el-button> -->
    <h1>{{ msg }}</h1>
    <p>
      Our overarching aims are to (1) understand the fundamental mechanisms that underlie diseases in the 
      <a href="https://www.england.nhs.uk/ourwork/clinical-policy/ltc/our-work-on-long-term-conditions/neurological/" 
        target="_blank" rel="noopener">neurological</a>, 
      <a href="https://www.england.nhs.uk/ourwork/clinical-policy/cvd/"
      target="_blank" rel="noopener">cardiovascular</a> 
      and
      <a href="https://www.england.nhs.uk/ourwork/clinical-policy/ltc/our-work-on-long-term-conditions/musculoskeletal/"
      target="_blank" rel="noopener">musculoskeletal</a> 
      systems including
      <a href="https://www.hopkinsmedicine.org/health/conditions-and-diseases/types-of-muscular-dystrophy-and-neuromuscular-diseases"
      target="_blank" rel="noopener">neuromuscular</a> diseases  
      , and (2) develop therapeutic approaches to combat these diseases and/or 
      delay onset and progression.
    </p>
    <p>
      We integrate research approaches and data to address mechanisms that reach across the neurological, cardiovascular and
      musculoskeletal physiological systems.
    </p>

    <h3>Multimodal Approaches</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Magnetic_resonance_imaging" target="_blank" rel="noopener">Imaging</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Mathematical_model" target="_blank" rel="noopener">Mathematical Modelling</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Artificial_intelligence" target="_blank" rel="noopener">Artificial Intelligence</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Structural_biology" target="_blank" rel="noopener">Structural Biology</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Omics" target="_blank" rel="noopener">Omics</a></li>
      <li><a href="https://en.wikipedia.org/wiki/Computational_science" target="_blank" rel="noopener">Scientific Computing</a></li>
    </ul>
    <!-- <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
