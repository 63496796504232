<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/neuro_cardio_musculo_skeletal.jpg">
    <HelloWorld msg="Welcome to NCMSS (Neuro-Cardio-Musculo-Skeletal Science)"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
